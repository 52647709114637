@import './../../../../assets/sass/theme.scss';

.condensed-list {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: auto;

    &-header {
        font-size: 80%;
        color: #f1f1f1;
        margin: 0;
        padding: 8px 23px 8px 8px;
        font-weight: 500;
        line-height: 1.2;
    }

    &-body {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        position: relative;
    }
}

ul.condensed-list-items {
    margin: 0;
    padding: 0;

    li {
        list-style-type: none;
        font-size: 90%;
        color: #ccc;

        a.condensed-list-item {
            cursor: pointer;
            width: 100%;
            padding: 0.25em 1em;
            display: inline-block;
            text-decoration: none;

            &.active, &:hover {
                background-color: $lighter-2;
            }
            &.current{
                background-color: $lighter-3;
            }
        }
    }
}

