.app-settings-page {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    min-height: fit-content;
    overflow-y: auto;
    &-form {
        flex-grow: 1;
    }

    &-sidebar {
        flex-basis: 30vw;
    }
}
