@import "../../../../assets/sass/theme.scss";

.project-settings-page {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  height: 600px;

  &-settings {
    flex-grow: 1;
    overflow-y: auto;
  }

  &-metrics {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 325px;
    max-width: 420px;
    color: #fff;

    .loading {
      position: absolute;
      left: 50%;
      top: 25%;
    }

    p {
      font-size: 90%;
    }
  }

  svg {
    margin-top: 1em;
    text {
      fill: #fff;
      font-weight: 500;
    }
  }

  .asset-chart {
    font-size: 90%;

    @media (max-width: 1920px) {
      display: flex;
      align-items: center;
    }

    .hint-content {
      display: flex;
      color: #fff;
      background: #000;
      align-items: center;
      padding: 5px;

      &-box {
        height: 10px;
        width: 10px;
      }
    }

    .rv-sunburst {
      margin: 0 auto;
    }

    .rv-hint {
      white-space: nowrap;
    }
  }

  .tag-chart {
    margin: 0 auto;

    line {
      stroke: $lighter-5;
    }
  }
}

.assetCount {
  display: flex;
  align-items: center;
}

.project-saving {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  text-align: center;
  display: flex;
  .project-saving-spinner {
    margin: auto;
    font-size: 24px;
  }
}
